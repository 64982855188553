














import "reflect-metadata"
import { Component, Prop } from "vue-property-decorator"
import Vue from "vue"

@Component
export default class MultipassZone extends Vue {
  @Prop({ default: [] }) multipassSession!: any

  get purchasableZones(): any {
    if (!this.multipassSession) {
      return []
    }

    return this.multipassSession.session.session_zones.filter(
      (z: any) => z.is_purchasable
    )
  }

  onZoneChange(zoneId: string) {
    if (!zoneId || zoneId == this.$route.params?.zoneId) {
      return
    }

    this.$router.push({
      name: "multipass-zone",
      params: {
        lookupRef: this.$route.params?.lookupRef,
        sessionId: this.$route.params?.sessionId,
        zoneId: zoneId,
      },
      query: this.$defaultQuery(),
    })
  }
}
