

















import "reflect-metadata"
import { Component, Prop, Watch } from "vue-property-decorator"
import Vue from "vue"
import MultipassZoneMap from "@/components/multipass/MultipassZoneMap.vue"
import MultipassZoneSelector from "@/components/multipass/MultipassZoneSelector.vue"

@Component({
  components: { MultipassZoneMap, MultipassZoneSelector },
})
export default class MultipassZone extends Vue {
  @Prop() multipassConfig!: any
  @Prop() multipassSession!: any
  @Prop() cartKey!: string
  zone?: any = null

  mounted() {
    this.getMultipassZone()

    this.$store.subscribeAction((action) => {
      if (action.type == "multipassCart/invalidSeatsDetected") {
        this.getMultipassZone()
      }
    })
  }

  getMultipassZone() {
    this.$api
      .getMultipassZone(
        this.$route.params.zoneId,
        this.$route.params?.lookupRef,
        this.$route.params?.sessionId
      )
      .then((response: any) => {
        this.zone = response
      })
  }

  goToSession() {
    this.$router.push({
      name: "multipass-session",
      params: {
        lookupRef: this.multipassConfig.lookup_ref,
        sessionId: this.$route.params?.sessionId,
      },
      query: this.$defaultQuery(),
    })
  }

  get isNumbered() {
    return this.zone != null && this.zone.seat_type == "numbered"
  }

  @Watch("$route.params.zoneId")
  onRouterParamZoneChange() {
    this.getMultipassZone()
  }
}
